@import '~antd/dist/antd.css';

.login-form {
  width: 300px;
}

.login-form-button {
  width: 100%;
}

.login-form-button-container {
  margin: 0;
}

.login-form-error {
  margin-top: -62px;
  margin-bottom: 24px;
}

.site-form-item-icon {
  opacity: 0.7;
  margin-right: 3px;
}

.drop-form-error {
  margin-bottom: 12px;
}