@import '~antd/dist/antd.css';

html, body, #root {
  margin: 0;
  height: 100%;
  overflow: hidden;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}

#editor-container svg {
  height: 100%;
}

.canvas {
  height: 100%;
}

.diagram-container {
	background: #333333;
	width: 100%;
	height: 100%;
}

.center {
	text-align: center;
}

.custom-node {
	border: solid 2px gray;
	border-radius: 5px;
	width: 50px;
	height: 50px;
	display: flex;
	align-items: flex-start;
	justify-content: space-between;
	position: relative;
}

.custom-node-color {
	position: absolute;
	top: 50%;
	left: 50%;
	width: 20px;
	height: 20px;
	transform: translate(-50%, -50%);
	border-radius: 10px;
}

.circle-port {
	width: 12px;
	height: 12px;
	margin: 2px;
	border-radius: 4px;
	background: darkgray;
	cursor: pointer;
}

.circle-port:hover {
	background: mediumpurple;
}

.sidebar .property {
	font-size: 13px;
	border-bottom: 1px solid rgba(255,255,255,0.25);
	display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: row;
	width: 100%;
}

.sidebar .label {
	margin: auto;
	padding: 0.3rem;
	width: 48%;
	height: 30px;
	float: left;
}

.sidebar .value {
	text-align: center;
	padding: 0.3rem;
	width: 55%;
}

.sidebar .value.num-input {
	text-align: left;
	padding: 0;
}

.sidebar .value.num-input .ant-input-number {
	width: 60%;
	height: 33px !important;
}

.sidebar .value.num-input button {
	width: 40%;
	height: 100%;
}


.sidebar h1 {
	padding: 0.2rem;
	border-bottom: 1px solid rgba(255,255,255,0.25);
	background-color: #303030;
	color:rgba(255,255,255,0.75);
	text-align: center;
	font-size: 12px;
	font-weight: bold;
	height: 1.5rem;
	line-height: 1.025rem;
	margin: 0;
}

.sidebar button {
	height: 38px;
	border-radius: 0px;
	border-bottom: 1px solid #5a5a5a;
}

.sidebar button:hover, .sidebar button:focus {
	border-bottom: 1px solid #5a5a5a;
}

.ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-up-inner, .ant-input-number-handler-wrap .ant-input-number-handler .ant-input-number-handler-down-inner {
	font-size: 9px !important;
}

.alias-row {
	font-family: "monospace"
}

.canvas>svg:not(:root) {
  overflow: visible;
}

.redel-card {
	margin: 8px 8px;
	width: 25em;
	box-shadow: 5px 10px 12px 12px #00000033;
}

.redel-title-hover {
	position: absolute;
	height: 50px;
	left: 0;
	top: 0;
	width: 25em;
}

.deleteBtnIcon {
	height: 100%;
}

.deleteBtnIcon>svg {
	width: 77%;
	height: 97%;
}